import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home } from './Home';
import { Navigation } from './Navigation';
import { Blog } from './Blog';
import { Movies } from './Movies';

export class App extends React.Component {
    render() {
        return (
            <Router>
                <div style={{
                    fontFamily: 'Lato'
                }}>
                    <Navigation/>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/blog" component={Blog}/>
                    <Route exact path="/movies" component={Movies}/>
                    <footer className="mb-1">
                        <div className="text-sm flex justify-center content-center align-center">
                            <p>
                                <span>Tim David Brachwitz</span> - Selbstgebaut mit <a href="https://reactjs.org/"><i className="fab fa-react"></i></a> und <i className="fas fa-heart"></i>
                            </p>
                        </div>
                    </footer>
                </div>
            </Router>
        );
    }
}