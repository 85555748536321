
import React from 'react';

const asm = `push eax
push ebx
call 0xDEADBEEF
add esp, 8`
const java = `public class Car {
    public void drive() {
        System.out.println("Brummm!");
    }
}`
const fib = `function fib(n) {
    if (n < 2) return 1;
    return fib(n-1) + fib(n-2);
}`

const tex = `\\documentclass{article}
\\begin{document}
    Hallo!
\\end{document}`
const python = `def main():
    print('Huhu!')`

const code = [
    asm,
    java,
    fib,
    tex,
    //python,
]
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from 'prism-react-renderer/themes/github'

//<pre>
//<code className="font-mono text-blue-500">
//    {code[Math.floor(code.length * Math.random())]}
//</code>
//</pre>

export class CodeBlock extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="overflow-auto">
                <Highlight {...defaultProps} theme={theme} code={this.props.children || code[Math.floor(code.length * Math.random())]} language={this.props.lang ||'javascript'}>
                    {({ className, style, tokens, getLineProps, getTokenProps }) => (
                        <pre className={className + ' p-2 rounded border border-blue-300'} style={style}>
                            {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })}>
                                {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                                ))}
                            </div>
                            ))}
                        </pre>
                    )}
                </Highlight>
            </div>
        );
    }
}