import React from 'react';
import { render } from 'react-dom';

import { App } from './components/App'

import 'tailwindcss/dist/tailwind.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

import "react-image-gallery/styles/css/image-gallery.css";

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Lato:300,400,700', 'sans-serif']
  }
});

render(<App/>, document.getElementById('app'));