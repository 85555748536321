import React from 'react';
import { Formel } from './Formel';
import { CodeBlock } from './CodeBlock';
import { Chart } from './Chart';

export class Home extends React.Component {
    render() {
        return (
            <div>
                <div className="p-3 bg-gray-400 flex">
                    <img src="https://s3.eu-central-1.amazonaws.com/tdbr.de/avatar.jpg" alt="" className="w-48 rounded bg-gray-500 mr-3 flex-none"/>
                    <div className="flex-shrink">
                        <p className="font-bold lg:text-4xl">Huhu!</p>
                        <div className="hidden">
                            <span>Autor: Tim David Brachwitz</span>
                            <span> bzw. </span>
                            <span>David Brachwitz</span>
                        </div>
                        <p className="lg:text-xl">David hier, wissbegieriger und neugieriger Student aus Berlin.</p>
                        <p className="lg:text-xl">Ich liebe Mathematik, Informatik und Wirtschaft.</p>
                        <p className="italic my-3">„Es gibt soviel da draußen, das gewusst werden will.“</p>
                    </div>
                    
                </div>
                <div className="p-3 lg:flex">
                    <div className="p-3 rounded shadow-lg border border-teal-300 lg:mr-3 lg:w-1/3">
                        <p className="text-center font-bold lg:text-xl">Mathematik</p>
                        <Formel/>
                        <div className="text-xs p-3 mt-3 lg:text-base">
                            Meine Lieblingsgebiete der Mathematik sind Stochastik, Analysis, Finanzmathematik sowie Maß- und Integrationstheorie.
                            In meiner Freizeit betreibe ich einen <a href="https://www.instagram.com/formeln/" target="_blank" className="text-blue-500 no-underline hover:text-blue-700">Mathe-Instagram-Account <i className="fab fa-instagram"></i></a>.
                        </div>
                    </div>
                    <div className="p-3 rounded shadow-lg border border-teal-300 mt-3 lg:mt-0 lg:w-1/3">
                        <p className="text-center font-bold mb-3 lg:text-xl">Informatik</p>
                        <CodeBlock/>
                        <div className="text-xs p-3 rounded mt-3 lg:text-base">
                            Ich programmiere gerne.
                            Backends in meiner Muttersprache Java und Frontends oder kleinere WebApps mit dem JavaScript Ökosystem.
                            Mathematische Simulationen schreibe ich in Python.
                            Als Infrastruktur bevorzuge ich den Cloud Computing Provider Amazon Web Services.
                            Außerdem interessiere ich mich für Reverse Engineering, Machine Learning und theoretische Informatik.
                        </div>
                        <div className="h-px bg-teal-300 rounded m-3"></div>
                        <div className="flex justify-center">
                            <div className="bg-gray-400 rounded p-2 inline-block">
                                <a href="https://projecteuler.net/" target="_blank">
                                    <img src="https://projecteuler.net/profile/LuxXx.png"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="p-3 rounded shadow-lg border border-teal-300 mt-3 lg:mt-0 lg:ml-3 lg:w-1/3">
                        <p className="text-center font-bold lg:text-xl">Wirtschaft</p>
                        <Chart/>
                        <div className="text-xs p-3 mt-3 lg:text-base">
                            In der Uni habe ich mich mit Investitions- und Finanzierungstheorie, Mikro und Makroökonomie befasst.
                            Dazu kommt die Kapitalmarkttheorie, Optionspreistheorie, Derivate und vieles mehr.
                            Ich zähle mich zum Lager der passiven Anleger und verfolge diese Strategie privat mit ETFs.
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="rounded shadow-lg border border-teal-300 p-3">
                        <div className="text-xs lg:text-base">
                            Themen in der Schnittmenge dieser drei Gebiete wecken das meiste Interesse.
                            In meinem Master vertiefe ich diese Inhalte.
                            Das reicht von Data Science am Kapitalmarkt über AlgoTrading mittels Machine Learning bis hin zu Konzepten neuer Kryptowährungen.
                            In meiner Bachelorarbeit habe ich probabilistische Ansätze zur Konsensfindungen in Distributed Ledger Technologien untersucht, die z.B. in der Kryptowährung IOTA genutzt werden.
                            Falls du mit mir in Kontakt treten willst, melde dich gerne bei mir!
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}