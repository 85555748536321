import React from 'react';

export class BlogEntry extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <article id={this.props.id}>
                <div className="p-3">
                    <header>
                        <a href={'#' + this.props.id}>
                            <p className="font-bold text-xl text-black">{this.props.title}</p>
                        </a>
                    </header>
                    <p className="font-thin text-black">{this.props.subtitle}</p>
                </div>
                <div className="h-px" style={{
                    background: 'linear-gradient(to right, #38b2ac, #4299e1)'
                }}></div>
                <div className={'p-3 text-black'}>
                    <section className="content">
                        {this.props.children}
                    </section>
                </div>
                {!this.props.last && 
                <div className="h-1 rounded"  style={{
                    background: 'linear-gradient(to right, #38b2ac, #4299e1)'
                }}></div>
                }
                <div className={this.props.last ? '' : 'mb-8'}></div>
            </article>
        )
    }
}