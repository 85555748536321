import React from 'react';

const movies = [
    'Interstellar',
    'Inception',
    '22.11.63',
    'Prestige - Meister der Magie',
    'Sieben',
    'Die Verurteilten',
    'Das perfekte Verbrechen',
    'Vanilla Sky',
    'Wolf of Wallstreet',
    'Heat',
    'Arrival',
    '21',
    'Saw',
    'Gone Girl',
    'Ohne Limit',
    'American Beauty',
    'Big Fish',
    'Gesetz der Rache',
    'Training Day',
    'A World Beyond',
    'Django Unchained',
    'León der Profi',
    'V wie Vendetta',
    'Die Unfassbaren',
    'In Time',
    'Patch Adams',
    'Pulp Fiction',
    'The Big Lebowski',
    'Der Marsianer',
    'Live Die Repeat (Edge of Tomorrow)',
    'Predestination',
    'Lord of War',
    'Focus',
    'The Thirteenth Floor',
    'Sieben Leben',
    'Escape Plan',
    'Shutter Island',
    'Ziemlich beste Freunde',
    'Life of Pi',
    'Dallas Buyers Club',
    'Fight Club',
    'Der Butler',
    'Imitation Game',
    'Kingsman',
    'Guardians of the Galaxy',
    'Ex Machina',
    'Cloud Atlas',
    'Das wandelne Schloss (Anime)',
    'Verborgende Schönheit',
    'Chihiros Reise ins Zauberland (Anime)',
    'Vielleicht lieber morgen',
    'Rain Man',
    'Wie ein einziger Tag',
    'Das Leben des Brian',
    'Pans Labyrinth',
    'Inside Man',
    'Scarface',
    'Schindlers Liste',
    'Das Leben ist schön',
    'A beautiful Mind',
    'Tricks',
    'Good Will Hunting',
    'Project Almanac',
    'Remember Me',
    'Batman Trilogie',
    'Der Untergang',
    'Hannibal (Quadrologie)',
    'Memento',
    'Kein Ort ohne Dich',
    'American Sniper',
    'Margos Spuren',
    'Die 9 Pforten',
    'Inglourious Basterds',
    'Headhunters',
    'Der Clou',
    'Passengers',
    'First Time',
    'Die Truman Show',
    'A Slumdog Millionaire',
    'Der Soldat James Ryan',
    'Das erstaunliche Leben des Walter Mitty',
    'Equilibrium',
    'Triangle',
    'Stadt der Engel',
    'Collateral',
    'American Gangster',
    'Project Peacemaker',
    'Das Schicksal ist ein mieser Verräter',
    'Das Leuchten der Stille',
]



export class Movies extends React.Component {
    render() {
        return (
            <div>
                <div className="p-3">
                    {movies.map((m, i) => {
                        return (
                            <div className={"text-center border-teal-500 border" + (i == 0 ? '': ' border-t-0')}>
                                <div className="p-3 m-3 text-center inline-block">{m}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }    
}