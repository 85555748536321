import React from 'react';
import { Label, XAxis, YAxis, ReferenceLine, ResponsiveContainer, LineChart, Line } from 'recharts';

export class Chart extends React.Component {

    constructor() {
        super();
        this.data = [];

        const max = 200;
        const div = Math.floor(max * (0.75 - Math.random()/2));
        this.divider = div;
        let k = 0;
        for (let i = 0; i < div; i++) {
            k += Math.floor(Math.random() * 8) - 4;
            this.data.push({
                name: i,
                laune: k
            });
        }
        for (let i = div; i < max; i++) {
            k += Math.floor(Math.random() * 8) - 2;
            this.data.push({
                name: i,
                laune: k
            });
        }
    }

    render() {
        return (
            <div>
                <ResponsiveContainer className="mx-auto" width='100%' aspect={4.0/2.0}>
                    <LineChart data={this.data}>
                    <YAxis axisLine={false} tickLine={false} tick={false} >
                        <Label angle={-90} fontSize="60%">
                            Deine Laune
                        </Label>
                    </YAxis>
                    <XAxis axisLine={false} tickLine={false} tick={false}>
                        <Label fontSize="60%">
                            Der Tag, an dem sich unsere Wege kreuzten
                        </Label>
                    </XAxis>
                        <ReferenceLine x={this.divider} stroke="#81e6d9" strokeDasharray="10">
                            <Label angle={-90} position="left" offset={20} fontSize="60%">
                                Der Tag
                            </Label>
                        </ReferenceLine>
                        <Line dot={false} type="linear" dataKey="laune" stroke="#4299e1" animationDuration={15000}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}