import React from 'react';
import { BlogEntry } from './BlogEntry';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import MathJax from 'react-mathjax';
import ImageGallery from 'react-image-gallery';
import { CodeBlock } from './CodeBlock';

const Inline = ({children}) => {
    return (
        <MathJax.Node inline formula={children}></MathJax.Node>
    )
}
const Formula = ({children}) => {
    return (
        <MathJax.Node formula={children} className="overflow-x-auto"></MathJax.Node>
    )
}

const Sep = () => {
    return (
        <div className="m-2 flex justify-center">
        <div className="h-1 rounded w-1/2"  style={{
            background: 'linear-gradient(to right, #38b2ac, #4299e1)'
        }}></div></div>
    )
}

let gallery = [
    'https://tdbr.de/blog/out_-0.5_-0.5_-0.5.png',
    'https://tdbr.de/blog/out_-1.0_1.0_1.0.png',
    'https://tdbr.de/blog/out_0.0_0.0_0.0.png',
    'https://tdbr.de/blog/out_0.0_0.0_1.0.png',
    'https://tdbr.de/blog/out_0.0_1.0_0.0.png',
    'https://tdbr.de/blog/out_0.0_1.0_1.0.png',
    'https://tdbr.de/blog/out_0.5_0.5_0.5.png',
    'https://tdbr.de/blog/out_1.0_-1.0_1.0.png',
    'https://tdbr.de/blog/out_1.0_0.0_0.0.png',
    'https://tdbr.de/blog/out_1.0_0.0_1.0.png',
    'https://tdbr.de/blog/out_1.0_1.0_-1.0.png',
    'https://tdbr.de/blog/out_1.0_1.0_0.0.png',
    'https://tdbr.de/blog/out_1.0_1.0_1.0.png'
]
gallery = gallery.map(e => { return {original: e, thumbnail: e} })

export class Blog extends React.Component {
    render() {
        return (
            <div>
                <div className="p-3 m-3">
                    <Link className="no-underline text-teal-800 font-bold hover:text-teal-700" to="/"><i className="fas fa-backward"></i> Zurück zur Startseite</Link>
                </div>
                <div className="container mx-auto">
                    <BlogEntry id="diversifikation" title="Optimales Risiko dank Diversifikation" subtitle="04.09.2019 - Tim David Brachwitz">
                        <a target="_blank" href="https://medium.com/@david.brachwitz/diversifikation-aea350bc90f5" className="no-underline hover:text-teal-600">
                            Mein erster Blogartikel auf Medium über den Effekt der Diversifikation
                        </a>
                    </BlogEntry>
                    <div className="h-24"></div>
                    {/*
                    <BlogEntry title="Was ist eine Risikoprämie?" subtitle="Wie man einen Untertitel macht">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.
                    </BlogEntry>
                    <BlogEntry title="Meine Bachelorarbeit - Sicherheit und Stabilität in Distributed Ledger Technologies" subtitle="Wie man einen Untertitel macht">
                    Hallo. Ich bin ein kleiner Blindtext. Und zwar schon so lange ich denken kann. Es war nicht leicht zu verstehen, was es bedeutet, ein blinder Text zu sein: Man macht keinen Sinn. Wirklich keinen Sinn. Man wird zusammenhangslos eingeschoben und rumgedreht – und oftmals gar nicht erst gelesen. Aber bin ich allein deshalb ein schlechterer Text als andere? Na gut, ich werde nie in den Bestsellerlisten stehen. Aber andere Texte schaffen das auch nicht. Und darum stört es mich nicht besonders blind zu sein. Und sollten Sie diese Zeilen noch immer lesen, so habe ich als kleiner Blindtext etwas geschafft, wovon all die richtigen und wichtigen Texte meist nur träumen.
                    </BlogEntry>
                    <BlogEntry title="Symbolische Wahrscheinlichkeitstheorie" subtitle="the characteristic function of the standard normal distribution">
                        <a target="_blank" href="https://gist.github.com/LuxXx/9f7aad04e5e05ea1f64f7c3e80f4a10c">https://gist.github.com/LuxXx/9f7aad04e5e05ea1f64f7c3e80f4a10c</a>
                    </BlogEntry>
                    <BlogEntry title="Investition und Finanzierung - TU Berlin" subtitle="Wie man einen Untertitel macht">
                        <a target="_blank" href="https://if.tdbr.de/">if.tdbr.de/</a>
                    </BlogEntry>
                    <BlogEntry title="RGN Spielerstatistiken" subtitle="Wie man einen Untertitel macht">
                        <a target="_blank" href="http://rgn.tdbr.de/">rgn.tdbr.de/</a>
                    </BlogEntry>
                    <BlogEntry title="Mintos Kontostand" subtitle="Wie man einen Untertitel macht">
                    </BlogEntry>
                    <BlogEntry title="BICCX" subtitle="Wie man einen Untertitel macht">
                    </BlogEntry>
                    <BlogEntry title="St. Petersburg" subtitle="Wie man einen Untertitel macht">
                    </BlogEntry>
                    <BlogEntry title="RageMP Spielerstatistiken" subtitle="Wie man einen Untertitel macht" last={true}>
                        <a target="_blank" href="https://gist.github.com/LuxXx/d80dbf48f36c7e362815bb545507385d">https://gist.github.com/LuxXx/d80dbf48f36c7e362815bb545507385d</a>
                    </BlogEntry>


 
                    CryptoCurrency Correlation Chart
                    Der Leverage Effekt
                    */}
                    
                </div>
                <div className="p-3 m-3">
                    
                    <Link className="no-underline text-teal-800 font-bold hover:text-teal-700" to="/"><i className="fas fa-backward"></i> Zurück zur Startseite</Link>
                </div>
            </div>
        )
    }
}