import React from 'react';
import { Link } from 'react-router-dom';

const mail = 'provoziert'.replace('o', '0').replace('o', '0') + '@' + 'gmx' + '.' + 'de'

export class Navigation extends React.Component {
    render() {
        //  border border-b-4 border-t-0 border-l-0 border-r-0 border-green
        return (
            <div>
                <nav className="flex items-center justify-between flex-wrap bg-gray-900 p-2 text-2xl lg:text-xl">
                    <div>
                        <Link className="no-underline text-teal-500 font-bold inline-block hover:text-teal-300" to="/">Tim David</Link>
                        <div className="inline-block text-teal-700 mx-3">|</div>
                        <Link className="no-underline text-teal-600 font-thin inline-block hover:text-teal-400" to="/blog">Blog</Link>
                    </div>
                    <div>
                        <a href={"mailto:" + mail} className="mr-1 text-blue-500 hover:text-blue-700"><i className="fa fa-envelope"></i></a>
                        <a href="https://www.linkedin.com/in/tdbr/" className="mr-1 text-blue-500 hover:text-blue-700" target="_blank"><i className="fab fa-linkedin"></i></a>
                        <a href="https://twitter.com/luxdav" className="mr-1 text-blue-500 hover:text-blue-700" target="_blank"><i className="fab fa-twitter"></i></a>
                        <i className="fab fa-discord mr-1 text-blue-500 group-hover:text-white hover:text-blue-700"></i>
                        <a href="https://github.com/LuxXx" className="mr-1 text-blue-500 hover:text-blue-700" target="_blank"><i className="fab fa-github"></i></a>
                        <a href="https://gist.github.com/LuxXx" className="mr-1 text-blue-500 hover:text-blue-700" target="_blank"><i className="fab fa-github-alt"></i></a>
                    </div>
                </nav>
                <div className="h-2" style={{
                    background: 'linear-gradient(to right, #38b2ac, #4299e1)'
                }}></div>
            </div>
        )
    }
}
