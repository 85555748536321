import React from 'react';
import MathJax from 'react-mathjax';

const fourier = `\\int_{-\\infty}^\\infty
    \\hat f(\\xi)\\,e^{2 \\pi i \\xi x}
    \\,d\\xi`
const lebesgue = `\\int_\\Omega \\phi\\ \\mathrm{d}\\mu=\\sum_{i=1}^n\\alpha_i\\mu(A_i)`
const euler = `\\mathrm{e}^{\\mathrm{i}\\pi}+1=0`
const umkehr = `(f^{-1})'(y) = \\frac{1}{f'(f^{-1}(y))} = \\frac{1}{f'(x)}`
const euler2 = `\\lim_{n\\to\\infty} \\left(1+\\frac {z}{n}\\right)^n=e^z`
const euler3= `e
=
\\frac{2}{1}
\\sqrt[2]{\\frac{4}{3}}
\\sqrt[4]{\\frac{6}{5}\\frac{8}{7}}
\\sqrt[8]{\\frac{10}{9}\\frac{12}{11}\\frac{14}{13}\\frac{16}{15}}
...`
const stochconv = `\\lim_{n\\to\\infty}\\mathbb{P}(|X_n-X|\\geq\\varepsilon)=0`
const maths = [
    stochconv,
    fourier,
    lebesgue,
    euler,
    umkehr,
    euler2,
    euler3,
]

export class Formel extends React.Component {
    render() {
        return (
            <div className="text-blue-500 overflow-auto">
                <MathJax.Provider>
                    <MathJax.Node formula={maths[Math.floor(maths.length * Math.random())]}></MathJax.Node>
                </MathJax.Provider>
            </div>
        );
    }
}